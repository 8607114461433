import { Link } from "gatsby"
import React from "react"

const Menu = () => (
    <div className="collapse navbar-collapse text-uppercase" id="navbarContent">
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
                <Link to="/about" className="nav-link">About</Link>
            </li>
            <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact</Link>
            </li>
        </ul>
    </div>
)

export default Menu