import PropTypes from "prop-types"
import { Link } from "gatsby"
import React from "react"
import Menu from "./menu"
import logo from '../images/CC-lgex280.png'

const Header = ({ siteTitle }) => (
  <header className="site-header fixed-top bg-light-green border-bottom border-dark">
    <a href="#content" className="sr-only sr-only-focusable">Skip to content</a>
    <div className="container">
        <nav className="navbar main-nav navbar-expand-lg navbar-white" aria-label="Main navigation">
        <Link to="/" className="navbar-brand d-inline-flex text-uppercase"><img src={logo} alt="Logo" /></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Menu />
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Chai Chuah`,
}

export default Header
