import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";
import logo_full from '../images/CC-lgex280.png'
import linkedin_logo from "../images/linkedin-small.png"


const Footer = () => (
    <footer className="bg-light-green text-black">
        <div className="container pt-6 pb-2">
            <div className="row justify-content-md-between">
                <div className="col-4 col-md-2 mb-4">
                    <Link to="/" className="h6 mb-3"><img className="footerLogo" src={logo_full} alt="Logo" /></Link>
                    {/* <p className="text-muted">Former Director-General , New Zealand Ministry of Health</p> */}
                </div>
                <div className="col-sm-12 col-md-4 mb-4 text-right">
                    <a rel="noopener noreferrer nofollow" target="_blank" href="https://nz.linkedin.com/in/chai-%E3%80%82%E4%BB%81%E6%9D%90-chuah-%E8%94%A1-4801855a?trk=author_mini-profile_title">
                        <img className="footerLogo" src={linkedin_logo} alt="logo"/>
                    </a>
                </div>
            </div>
            <hr />
            <p className="small text-center">&copy; {new Date().getFullYear()}, <a href="http://visualindustrie.co.nz">Visual Industrie</a></p>
        </div>
        <Helmet>
            <script src={'theme.min.js'} async />
        </Helmet>
    </footer>
)

export default Footer